import React, { Component } from "react"
import SEO from "../components/seo"
import ExtendedContent from "../components/extendedContent"
import { createGlobalStyle } from "styled-components"
import HomeSlider from "../components/common/home-slider/homeSlider"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

const GlobalStyles = createGlobalStyle`
	body {
	 background-color: ${({ theme }) => theme.colors.mainPurple} !important;
	 background-image: none;
	}
`

class IndexPage extends Component {

  componentDidMount() {
    const { dispatch, pageContext } = this.props
    dispatch(setTopColors("fiolety"))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {
    const { data } = this.props
    const { entries } = data;
    const slides = entries?.nodes?.[0].archeeSlider || ""
    const extendedContentData = entries?.nodes?.[0].archeeTrescPodstrony || ""
    return (
      <>
        <GlobalStyles/>
        <HomeSlider slides={slides}/>
        <ExtendedContent data={extendedContentData}/>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(IndexPage)


export const query = graphql`
    query($remoteId: ID, $langKey: String){
            seo: allCraftArcheeStronaGlownaArcheeStronaGlownaEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes {
                    ...seoIndex
                }
            }
            entries: allCraftArcheeStronaGlownaArcheeStronaGlownaEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}){
                nodes {
                    ...slider,
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        #                    ...component,
                        ...filmLink,
                        ...przycisk
                        ...modulyGraficzne
                    }
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}