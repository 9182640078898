import React from "react"
import styled from "styled-components"
import Slider from "react-slick/lib"
import PageContainer from "../../pageContainer"
import HeroStep1 from "../../../images/krokk1.svg"
import HeroStep2 from "../../../images/archee_krok_2.svg"
import HomeSlide from "./homeSlide"

const settings = {
  infinite: true,
  arrows: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  pauseOnHover: true,
  dots: true,
}

const StyledSiderWrapper = styled.div`
  margin: 65px 0 180px; 
  position: relative;
  @media( min-width: 992px ) {
    margin: 45px 0 0;  
  }  
  .slick-slider {
    position: static;
    ul.slick-dots {
      margin-top: 5px;
      li {
        margin: 0;
        button {
          &::before {
            font-size: 11px;
            color: ${({theme}) => theme.colors.sliderDot};
            opacity: 1;
          }
        }
        &.slick-active {
          button {
           &::before {
            opacity: 1;
            color: ${({ theme }) => theme.colors.sliderDotActive};
          }
         }
        }
      }
      @media( min-width: 992px ) {
        position: absolute;
        left: 50%;
        top: calc( 100% + 10px );
        transform: translate(-50%, -50%);      
      }
      @media( min-width: 1200px ) {
        top: calc( 100% + 10px );
      }
    }    
  }
`
const StyledHeroImgWrapper = styled.div`
  position: relative;
  top: -90px;
  @media( min-width: 992px ) {
    width: 35%;
    display: inline-block;
    margin-top: -70px;
    top: 0;
  }
  animation: jump_banner 4s ease-in-out 0s infinite;
  @keyframes jump_banner {
   0% {
     transform: translateY(0);
   }
   10% {
    transform: translateY(-15px);
   }
   15%{
     transform: translateY(0);
   }
   20% {
     transform: translateY(-5px);
   }
   25%,100%{
     transform: translateY(0);
   }
  }
`

const StyledSliderWrapper = styled.div`
  display: block;
  position: relative;
  top: 200px;
  @media( min-width: 992px ) {
    width: 65%;
    position: static;
    display: inline-block;
    vertical-align: top;
  }
`

const StyledIMG = styled.img`
  max-width: 100%;
  max-height: 300px;
  display: block;
  margin: 0 auto;
  &.opened{
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
   &.closed{
    opacity: 0;
    position: absolute;
    animation: closeEyes 4s ease-in-out 0s infinite;
    animation-direction: normal;
    @keyframes closeEyes {
      0%, 35% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      45%,100% {
        opacity: 0;
      }
    }
    }
  @media(min-width: 992px){
    max-width: 100%;
    transform: scale(1.35)
 }
`

const HomeSlider = ({ slides }) => {
  return (
    <PageContainer>
      <StyledSiderWrapper>
        <StyledHeroImgWrapper>
          <StyledIMG src={HeroStep2} className="mobile opened" alt="Archee HERO"/>
          <StyledIMG src={HeroStep1} className="mobile closed" alt="Archee HERO"/>
        </StyledHeroImgWrapper>
        <StyledSliderWrapper>
          <Slider {...settings}>
            {slides.map((slide, key) => <HomeSlide key={key} {...slide}/>)}
          </Slider>
        </StyledSliderWrapper>
      </StyledSiderWrapper>
    </PageContainer>
  )
}

export default HomeSlider
