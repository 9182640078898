import React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../../utils/getHtmlFromRedactorField"

const StyledSlide = styled.div`
  @media( min-width: 992px ) {
    padding-top: 30px;
    padding-left: 25px;
  }
`
const StyledHeader = styled.h1`
  font-size: 43px;  
  line-height: 46px;
  color: ${ ({ theme }) => theme.colors.mainTextWhite };
  margin-bottom: 20px;
  @media( min-width: 500px ) {
    font-size: 55px;  
    line-height: 59px;
  }
  @media( min-width: 768px ) {
    font-size: 89px;  
    line-height: 92px;
  }
  span{  
  &.archee-navy-blue {
      color: ${ ({ theme }) => theme.colors.componentsBlockNavy };  
  }
  &.archee-light-blue{
      color: ${ ({ theme }) => theme.colors.componentsBlockGreen };
  }
  &.archee-dark-grey{
      color: ${ ({ theme }) => theme.colors.mainTextDarkGrey };  
  }
 }
`
const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${ ({ theme }) => theme.colors.mainTextWhite };
  @media( min-width: 500px ) {
    font-size: 20px;
    line-height: 30px;
  }
`

const HomeSlide = ({ naglowek, opis }) => {
  return (
    <StyledSlide>
      <StyledHeader dangerouslySetInnerHTML={ getHtmlFromRedactorField(naglowek) }/>
      <StyledText dangerouslySetInnerHTML={ getHtmlFromRedactorField(opis) }/>
    </StyledSlide>
  )
}

export default HomeSlide
